<div #toastContainer></div>
<div class="separacion-interseccion-1"></div>
<div class="row w100 justify-content-center">
  <div class="col-md-4 col-sm-5">
    <div class="separacion-interseccion-3"></div>
    <div class="login-titulo1">Acceso a bonos</div>
    <div class="login-titulo2">Ingresar</div>

    <form style="margin-top: 25px" [formGroup]="form" (ngSubmit)="onSubmit()">
      <div style="display: flex; flex-direction: column; width: 85%">
        <!-- Input de contraseña -->
        <mat-form-field appearance="fill" style="width: 100%">
          <input
            matInput
            [type]="hide ? 'password' : 'text'"
            placeholder="Segunda Contraseña"
            formControlName="segundaContrasena"
          />
          <mat-icon
            style="color: #cdcdcd"
            class="material-symbols-outlined"
            matSuffix
            >lock</mat-icon
          >
        </mat-form-field>

        <!-- Botón alineado al final -->
        <div style="display: flex; justify-content: flex-end; margin-top: 10px">
          <button type="submit" style="width: 120px" class="btn btn-primary">
            Aceptar
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-column-filter',
  templateUrl: './modal-column-filter.component.html',
  styleUrl: './modal-column-filter.component.scss'
})
export class ModalColumnFilterComponent {
  columns: any[] = [];

  constructor(
    public dialogRef: MatDialogRef<ModalColumnFilterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.columns = data.columns;
  }

  // Selección única
  selectColumn(event: any): void {
    const selectedColumnLabel = event.source.value;

    // Desmarca todas las columnas excepto la seleccionada
    this.columns.forEach(column => {
      column.selected = (column.label === selectedColumnLabel);
    });

    // Cierra el modal después de seleccionar
    this.dialogRef.close(this.columns);
  }

  closeModal(): void {
    this.dialogRef.close(this.columns);
  }
}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrl: './toast.component.scss'
})
export class ToastComponent {
  @Input() message: string = '';
  @Input() icon: string = '';
  @Input() show: boolean = false;

  closeToast() {
    this.show = false;
  }
}

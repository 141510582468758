import { Component } from '@angular/core';
import { TokenInterceptorService } from 'src/app/service/interceptor/token-interceptor.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = 'Bonos';
  constructor(
    private interceptor: TokenInterceptorService,
  ) { 
    this.interceptor.ObtieneLtpaToken();
  }
    
}

<div class="modal-dialog modal-custom-width">
  <div class="modal-content">
    <div class="modal-header p-2">
      <h5 class="modal-title titulo">Columnas</h5>
      <button type="button" class="btn-close-custom" (click)="closeModal()">
        &times;
      </button>
    </div>

    <div class="modal-body p-3">
      <!-- Chips -->
      <mat-chip-listbox (change)="selectColumn($event)" [multiple]="false">
        <mat-chip-option  *ngFor="let column of columns" [selected]="column.selected">
          {{ column.label }}
        </mat-chip-option>
      </mat-chip-listbox>
    </div>
  </div>
</div>
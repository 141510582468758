<div
  *ngIf="show"
  class="toast show"
  role="alert"
  aria-live="assertive"
  aria-atomic="true"
>
  <div
    class="toast-body danger"
    style="
      color: whitesmoke;
      display: flex;
      align-items: center;
      line-height: 1;
    "
  >
    <!-- Icono dinámico -->
    <i
      [class]="icon"
      style="margin-right: 30px; color: whitesmoke; font-size: 20px"
    ></i>

    <!-- Mensaje -->
    <span style="color: whitesmoke">{{ message }}</span>

    <!-- Botón de cerrar -->
    <button
      type="button"
      class="close"
      (click)="closeToast()"
      style="background: none; border: none; margin-left: auto"
    >
      <span aria-hidden="true" style="color: whitesmoke">&times;</span>
    </button>
  </div>
</div>

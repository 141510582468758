import { LoadingComponent } from '@/app/components/loading/loading.component';
import { ToastComponent } from '@/app/components/toast/toast.component';
import {
  Component,
  OnInit,
  signal,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DatosCuaService } from '../../service/datosCua.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit {
  readonly cuaAcess = new FormControl('', [
    Validators.required,
    Validators.email
  ]);

  @ViewChild('toastContainer', { read: ViewContainerRef, static: true })
  toastContainer!: ViewContainerRef;
  hide = true;
  errorMessage = signal('');
  formCua!: FormGroup;
  loadingCua: any = '';
  constructor(
    private fb: FormBuilder,
    public serviceCua: DatosCuaService,
    public router: Router,
    public dialog: MatDialog
  ) {   
  }

  ngOnInit() {
    this.formCua = this.fb.group({
      cua: ['', Validators.required]
    });
  }

  showError(message: string, icon: string) {
    const componentRef = this.toastContainer.createComponent(ToastComponent);
    componentRef.instance.message = message;
    componentRef.instance.show = true;
    componentRef.instance.icon = icon;

    // Opcional: Ocultar el toast automáticamente después de unos segundos
    setTimeout(() => {
      this.toastContainer.clear();
    }, 5000); // El toast se cerrará después de 3 segundos
  }

  updateErrorMessage() {
    if (this.cuaAcess.hasError('required')) {
      this.errorMessage.set('Campo requerido');
    } else {
      this.errorMessage.set('');
    }
  }

  getCua() {
    if (this.formCua.invalid) {
      return;
    } else {
      this.loadingCua = this.dialog.open(LoadingComponent);
      sessionStorage.setItem('cua', this.formCua.value.cua);
      this.serviceCua.getRol(this.formCua.value.cua).subscribe(
        (data: any) => {
          if (!data.message) {
            let dia = new Date();
            let anio = dia.getFullYear();
            sessionStorage.setItem('rol', data.data.rol);
            this.getSegmentos(data.data.rol, anio);
            this.loadingCua.close();
          } else {
            this.showError(
              'No se encontraron registros',
              'iziToast-icon fas fa-exclamation revealIn'
            );
            this.loadingCua.close();
          }
        },
        (error) => {
          this.showError(
            'Error al cargar el servicio. Intenta de nuevo.',
            'iziToast-icon fas fa-exclamation revealIn'
          );
          this.loadingCua.close();
        }
      );
    }
  }

  getSegmentos(rol: any, anio: any) {
    let loadingC = this.dialog.open(LoadingComponent);
    this.serviceCua
      .getRamosSegmentos(this.formCua.value.cua, anio, rol)
      .subscribe(
        (dataSeg: any) => {
          if (!dataSeg.message) {
            loadingC.close();
            this.router.navigate(['/segmentos'], {
              state: { data: dataSeg, anio: anio }
            });
          } else {
            this.showError(
              'dataSeg.message',
              'iziToast-icon fas fa-exclamation revealIn'
            );
            loadingC.close();
          }
        },
        (error) => {
          loadingC.close();
          this.showError(
            'Error al cargar el servicio. Intenta de nuevo.',
            'iziToast-icon fas fa-exclamation revealIn'
          );
        }
      );
  }
}

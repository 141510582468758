import { Injectable } from "@angular/core";
import { jwtDecode } from "jwt-decode";


@Injectable()
export class JwtDecodeService{
    constructor(){
        console.log("Inicia servioc JWT");
    }

    DecodeToken(token: any): any {
        return jwtDecode(token);
        }
}





import { LoadingComponent } from '@/app/components/loading/loading.component';
import { ToastComponent } from '@/app/components/toast/toast.component';
import { DatosCuaService } from '@/app/service/datosCua.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login-intermediario',
  templateUrl: './login-intermediario.component.html',
  styles: [
    `
      h3 {
        font-weight: bold;
        margin-bottom: 20px;
      }

      .btn-primary {
        background-color: #ff6f00;
        border-color: #ff6f00;
      }
    `
  ]
})
export class LoginIntermediarioComponent {

  
  @ViewChild('toastContainer', { read: ViewContainerRef, static: true })
  toastContainer!: ViewContainerRef;

  form: FormGroup;
  hide = true;
  rol: string = '';
  

  constructor(
    private datosCuaService: DatosCuaService,
    private fb: FormBuilder,
    public route: Router,
    public dialog: MatDialog
  ) {
    this.form = this.fb.group({
      segundaContrasena: ['']
    });
  }

  ngOnInit(): void {
    
  }

  
  showError(message: string, icon: string) {
    const componentRef = this.toastContainer.createComponent(ToastComponent);
    componentRef.instance.message = message;
    componentRef.instance.show = true;
    componentRef.instance.icon = icon;

    // Opcional: Ocultar el toast automáticamente después de unos segundos
    setTimeout(() => {
      this.toastContainer.clear();
    }, 5000); // El toast se cerrará después de 3 segundos
  }

  onSubmit() {
    const cua = '70292'; // CUA proporcionado por el usuario
    const idCua = '70292001'; // CUA utilizado para el servicio de bonos
    sessionStorage.setItem('cua', idCua);
    const password = this.form.get('segundaContrasena')?.value; // Obtiene el valor de la segunda contraseña

    // Validar que el campo de contraseña no esté vacío
    if (!password || password.trim() === '') {
      this.showError(
        'Por favor ingresa una contraseña.',
        'iziToast-icon fas fa-exclamation revealIn'
      );
      return; // Salir de la función si el campo está vacío
    }

    console.log('password', password);

    // Llama al servicio getBonos de manera independiente
    let loadingPasB = this.dialog.open(LoadingComponent);
    this.datosCuaService.getBonos(idCua).subscribe(
      (bonosResponse) => {
        console.log('Respuesta del servicio getBonos:', bonosResponse);
        this.rol = bonosResponse.data.rol;
        sessionStorage.setItem('rol', bonosResponse.data.rol);
        loadingPasB.close();
      },
      (error: HttpErrorResponse) => {
        // Manejo de errores para getBonos
        console.error('Error al obtener los datos de bonos:', error);
        this.showError(
          'Error al obtener los datos de bonos. Intenta de nuevo.',
          'iziToast-icon fas fa-exclamation revealIn'
        );
        loadingPasB.close();
      }
    );

    // Llama al servicio postSegundoPwd de manera independiente
    let loadingPas = this.dialog.open(LoadingComponent);
    this.datosCuaService.postSegundoPwd(password, cua).subscribe(
      (response) => {
        console.log('Respuesta del servicio postSegundoPwd:', response);

        // Verifica la respuesta del servicio postSegundoPwd
        if (response.bcaBonosSegundoPwdReturn.user.msg === false) {
          loadingPas.close();
          console.error('Error: Contraseña incorrecta');
          this.showError(
            'Contraseña incorrecta. Intenta de nuevo.',
            'iziToast-icon fas fa-exclamation revealIn'
          );
        } else {
          console.log('Contraseña correcta. Acceso permitido.');
          let dia = new Date();
          let anio = dia.getFullYear();
          this.datosCuaService
          .getRamosSegmentos(idCua, anio, this.rol)
          .subscribe(
            (dataSeg: any) => {
              if (!dataSeg.message) {
                loadingPas.close();
                this.route.navigate(['/segmentos'], {
                  state: { data: dataSeg, anio: anio }
                });

                loadingPas.close();
              } else {
                this.showError(
                  dataSeg.message,
                  'iziToast-icon fas fa-exclamation revealIn'
                );
                loadingPas.close();
              }
            },
            (error) => {
              loadingPas.close();
              this.showError(
                'Error al cargar el servicio. Intenta de nuevo.',
                'iziToast-icon fas fa-exclamation revealIn'
              );
            }
          );
        }
      },
      (error: HttpErrorResponse) => {
        // Manejo de errores para postSegundoPwd
        console.error('Error al validar la contraseña:', error);
        this.showError(
          'Error al validar la contraseña. Por favor, intenta de nuevo.',
          'iziToast-icon fas fa-exclamation revealIn'
        );
      }
    );
  }
}

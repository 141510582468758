import { HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { JwtDecodeService } from '../jwtDecodeService.service';

declare const Buffer: any;


@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {


  public decodedToken: any = '';
  public jwt: string = '';
  public tokenDecifrado:any = '';


  constructor(
    public router: Router,
    private jwtService: JwtDecodeService,
    private cookieService: CookieService,

  ) {
   }


   intercept(req: HttpRequest<any>, next: HttpHandler): any {
    let tokenReq: any;
        const headersapplication = new HttpHeaders ({
          'Content-Type': 'application/json',
          'x-api-key': '04985d13-d1d4-4032-9fe6-faa14c18d464'
        });
        tokenReq = req.clone({
          headers: headersapplication,
        }); 
    return next.handle(req);
  }

   LoginSesion<T>(jwt: any) {
    if(this.tokenDecifrado.tipousuario == 'intermediarios'){
      this.router.navigate(['/login-intermediario']);
    }else if(this.tokenDecifrado.tipousuario == 'Empleados'){
      this.router.navigate(['/home']);
    }

  } 

  getAllLocalStorageItems(): { [key: string]: any } {
      const items: { [key: string]: any } = {};
      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (key) {
          items[key] = localStorage.getItem(key);
        }
      }
      return items;
  }
  

  ObtieneLtpaToken(){
    
    //Para intermediario
    //this.jwt = "eyJhbGciOiJSUzI1NiIsImtpZCI6IjZjYzNmY2I2NDAzMjc2MGVlYjljMjZmNzdkNDA3YTY5NGM1MmIwZTMiLCJ0eXAiOiJKV1QifQ.eyJyb2wiOiJaaXZlcnVuIE5hcGVrb3Jpc3VoIiwibmVnb2Npb3NPcGVyYWJsZXMiOiJBU0FMR0EzMjgyNzMiLCJpZHBhcnRpY2lwYW50ZSI6IkFTQUxHQTMyODI3MyIsIm1haWwiOiJhZG9sZm9fYm9ub3NAY29ycmVvLmNvbSIsImFwZW1hdGVybm8iOiIiLCJnaXZlbm5hbWUiOiJBZG9sZm8gWml2ZXJ1biBOYXBla29yaXN1aCIsImFwZXBhdGVybm8iOiIiLCJjdWVudGFibG9xdWVhZGEiOmZhbHNlLCJ0aXBvdXN1YXJpbyI6ImludGVybWVkaWFyaW9zIiwicm9sZXMiOlsiQWdlbnRlcyIsIlNlZ3VybyBQZXJzb25hcyJdLCJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vZ25wLWJhc2V1bmljYWFnZW50ZXMtcWEtMjEwMDE3IiwiYXVkIjoiZ25wLWJhc2V1bmljYWFnZW50ZXMtcWEtMjEwMDE3IiwiYXV0aF90aW1lIjoxNzI3OTI0OTk5LCJ1c2VyX2lkIjoiQVNBTEdBMzI4MjczIiwic3ViIjoiQVNBTEdBMzI4MjczIiwiaWF0IjoxNzI3OTI0OTk5LCJleHAiOjE3Mjc5Mjg1OTksImZpcmViYXNlIjp7ImlkZW50aXRpZXMiOnt9LCJzaWduX2luX3Byb3ZpZGVyIjoiY3VzdG9tIn19.QeScNfwYA5GXUkQLIgdZB2vlLlLH-XCcDTGy56NWw5SaMuxe7sLToDnRuu9R3fgKYHJYFMZyOy7o5sdXrkpAyz0TPIkIJos11vnnEMVmotwDoRGXRWWhqo4f5mW3vXgD5e-h5ufg132KShmQDJHc0Czj2IwNOKUnIkPhVaVeElYzpxGxF6N727PpglTE2EW-Df_68RRk-fSFaWMnIdDE1s68gZnW4zA4t-AtlqLJxi6-ufqgyK0pLX76tj-N-RIrxiF9R_12JQIzjNqQukVOfftqYq7iXNcIVAemdDq2OwGpJBfE-6WUrDbj1ZetQp9UQrIk0zVq7PCfKiK2gwlhsg";
    //Para empleados
    // this.jwt = "eyJhbGciOiJSUzI1NiIsImtpZCI6IjZjYzNmY2I2NDAzMjc2MGVlYjljMjZmNzdkNDA3YTY5NGM1MmIwZTMiLCJ0eXAiOiJKV1QifQ.eyJyb2wiOiJST1NBUyBDQVJSQVNDTyIsIm5lZ29jaW9zT3BlcmFibGVzIjoiU1JDQVJSQVMiLCJpZHBhcnRpY2lwYW50ZSI6IlNSQ0FSUkFTIiwibWFpbCI6InNhdWwucm9zYXNAZ25wLmNvbS5teCIsImFwZW1hdGVybm8iOiJDQVJSQVNDTyIsImdpdmVubmFtZSI6IlNBVUwiLCJhcGVwYXRlcm5vIjoiUk9TQVMiLCJjdWVudGFibG9xdWVhZGEiOmZhbHNlLCJ0aXBvdXN1YXJpbyI6IkVtcGxlYWRvcyIsInJvbGVzIjpbIkFzZXNvciBTZXJ2aWNpbyIsIkNvbnN1bHRvciBDUk0iLCJWZW50YXMgQm9ub3MiLCJPcGVyYWNpb24gQm9ub3MiLCJCb25vcyBBZG1pbmlzdHJhZG9yIiwiQkNBIEFkbWluIiwiQXBwIEFndCBBZG1pbiIsIkxpZGVyIEZ1bmNpb25hcmlvIE5lZ29jaW8iLCJDb25zdWx0YSBPVFMgSGlzdG9yaWNhcyIsIk9wZXJhZG9yIENvbmV4aW9uIEFnZW50ZXMiLCJFZmljaWVuY2lhIEFnZW50ZXMiLCJBZG1pbmlzdHJhZG9yIEdhbGVyaWEiLCJBZG1pbmlzdHJhZG9yIEV2ZW50b3MgRGVzYXJyb2xsbyIsIkNvbGFib3JhZG9yIEdOUCJdLCJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vZ25wLWJhc2V1bmljYWFnZW50ZXMtcWEtMjEwMDE3IiwiYXVkIjoiZ25wLWJhc2V1bmljYWFnZW50ZXMtcWEtMjEwMDE3IiwiYXV0aF90aW1lIjoxNzI3OTMzNjg1LCJ1c2VyX2lkIjoiU1JDQVJSQVMiLCJzdWIiOiJTUkNBUlJBUyIsImlhdCI6MTcyNzkzMzY4NSwiZXhwIjoxNzI3OTM3Mjg1LCJmaXJlYmFzZSI6eyJpZGVudGl0aWVzIjp7fSwic2lnbl9pbl9wcm92aWRlciI6ImN1c3RvbSJ9fQ.H7P2FSllg3Sy5doh9zk3jIBAMJ1CqnvSFBydtDSEMJ_wJmzoZgYa-4g03MUr6JYVCn_lwPQl6w-fOGwfry6cofa1zJmAhIR5khCHLpcuLqkXQDNDeU58d5RvRu6mpy4lrsXT7KXK2UdI9OV5-PqCvAjCFyawOcqVjZ-xh-iKYD2sB9sdODq1CD1K_bpjrOV0U70scTa05BjSpiAqXXNOHl6ety-1KwMGZSbMTjKyCRDGwzbgK8zSlgj_Y0Udyg7tCf-IP6J8ZkVSsZ1tlBiDLMHrIMBYtDrI-UkeipDbvflk12NDw6SStiJN3Y2DqS3wsOmikfie_IgYcnBBJhXZTg";
    this.jwt = this.cookieService.get('jwt-bonos');
    this.SetJwt(this.jwt);
    this.getAllLocalStorageItems();
    this.LoginSesion(this.jwt);
  } 
  
  SetJwt(jwt:any){
    this.decodedToken = jwt ? jwt : "";
    if(this.decodedToken !== ""){
    this.tokenDecifrado = this.jwtService.DecodeToken(this.decodedToken);
    sessionStorage.setItem('idparticipante', this.tokenDecifrado.idparticipante);
    sessionStorage.setItem('tipousuario', this.tokenDecifrado.tipousuario);
    }
  }


}



<section class="row sin-margen" *ngIf="tieneTabla">
  <div class="col-md-12">
    <table id="tabladataproxy" class="table tabladetalle" data-page-length="10">
      <thead>
        <tr>
          <th scope="col" *ngFor="let th of columnas">{{ th.label }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let fila of paginatedRows">
          <td *ngFor="let f of fila">{{ f }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</section>

<section class="row sin-margen" *ngIf="!tieneTabla">
  <div class="col-md-12 text-center titulo1">
    No hay información a mostrar
  </div>
</section>

<!-- Controles de paginación -->
<section class="row sin-margen" *ngIf="totalPages > 1">
<div class="col-md-12">
  <nav>
    <ul class="pagination justify-content-center">
      <li class="page-item" [class.disabled]="currentPage === 1">
        <a class="page-link" (click)="changePage(currentPage - 1)">Anterior</a>
      </li>
      <li class="page-item" *ngFor="let page of [].constructor(totalPages); let i = index" 
          [class.active]="currentPage === (i + 1)">
        <a class="page-link" (click)="changePage(i + 1)">{{ i + 1 }}</a>
      </li>
      <li class="page-item" [class.disabled]="currentPage === totalPages">
        <a class="page-link" (click)="changePage(currentPage + 1)">Siguiente</a>
      </li>
    </ul>
  </nav>
</div>
</section>
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './view/home/home.component';
import { LoginIntermediarioComponent } from './view/login-intermediario/login-intermediario.component';
import { RamoComponent } from './view/ramo/ramo.component';
import { SegmentosComponent } from './view/segmentos/segmentos.component';
import { VerDetalleComponent } from './view/ver-detalle/ver-detalle.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'home', component: HomeComponent },
  { path: 'segmentos', component: SegmentosComponent },
  { path: 'ramo', component: RamoComponent },
  { path: 'ver-detalle', component: VerDetalleComponent },
  { path: 'login-intermediario', component: LoginIntermediarioComponent },
  { path: '**', redirectTo: 'home', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}

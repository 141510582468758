import { Component, Input, ViewChild } from '@angular/core';


@Component({
  selector: 'app-dynamic-table',
  templateUrl: './dynamic-table.component.html',
  styleUrl: './dynamic-table.component.scss'
})
export class DynamicTableComponent {
  @Input() columnas: { label: string }[] = []; 
  @Input() filas: any[] = [];
  @Input() tieneTabla: boolean = true;

  // Paginación
  currentPage: number = 1;
  itemsPerPage: number = 10; // Cambia esto si deseas más o menos filas por página
  paginatedRows: any[] = [];
  totalPages: number = 0;

  ngOnInit() {
    this.calculatePagination();
  }

  calculatePagination() {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    this.paginatedRows = this.filas.slice(startIndex, endIndex);
    this.totalPages = Math.ceil(this.filas.length / this.itemsPerPage);
  }

  changePage(page: number) {
    if (page < 1 || page > this.totalPages) return;
    this.currentPage = page;
    this.calculatePagination();
  }
}

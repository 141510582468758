export const environment = {
  pro: true,
  environmentName: 'QA',
  apikey : '04985d13-d1d4-4032-9fe6-faa14c18d464',
  apikeyPass : 'l7xxc2c17f9058254240afd3b24f52b86d65',
  urlServ: "https://bca-ws-qa.gnp.com.mx/",
  consultaBonosUrl: "consulta-bonos",
  urlServApi: 'https://api-qa.oscp.gnp.com.mx/',
  bcaBonos: 'bca-bonos/'
};

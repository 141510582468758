import { LoadingComponent } from '@/app/components/loading/loading.component';
import { ToastComponent } from '@/app/components/toast/toast.component';
import { Location } from '@angular/common';
import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import moment from 'moment-timezone';
import { DatosCuaService } from '../../service/datosCua.service';

@Component({
  selector: 'app-ramo',
  templateUrl: './ramo.component.html'
})
export class RamoComponent implements OnInit {
  cua: string = '';
  rol: string = '';
  detalle: any = [];
  bandera: boolean = false;
  idPagina: any = null;
  payload: any = [];
  esEntidad: boolean = false;
  ramos: any = [];
  arrRamos: any = [];
  arrRamosDeSubsegmento: any = [];
  anio: string = '';
  ramo: string = '';
  subSegmento: string = '';
  tipoPeriodo: string = '';
  activa: boolean = false;
  periodoMaximo: string = '';
  periodo: number = 0;
  periodoSufijo: string = 'er';
  titulo: string = '';
  segmento: string = '';
  tipoPeriodoMostrar:string = '';
  periodoMes:string = '';
  _ban_portalEntrada: string = '';

  nombreEntidad: string = '';
  entidad: any = [];

  @ViewChild('toastContainer', { read: ViewContainerRef, static: true })
  toastContainer!: ViewContainerRef;

  constructor(
    private _location: Location,
    public serviceCua: DatosCuaService,
    public dialog: MatDialog,
    public route: Router
  ) {}

  ngOnInit() {
    debugger;
    this.cua = sessionStorage.getItem('cua')!;
    this.rol = sessionStorage.getItem('rol')!;
    this._ban_portalEntrada = sessionStorage.getItem('tipousuario')!;
    
    if (history.state.data !== undefined) {
      this.esEntidad = history.state.esEntidad ? true : false;

      this.detalle = history.state.data.data;
      this.ramos = history.state.ramos;
      this.ramo = history.state.ramo;
      this.titulo = history.state.titulo;
      this.subSegmento = history.state.segmento;
      this.segmento = history.state.segmento;
      this.anio = history.state.anio;
      this.periodoMaximo = history.state.periodoMaximo;
      this.tipoPeriodo = history.state.tipoPeriodo;
      this.periodo = parseInt(history.state.periodo);
      sessionStorage.setItem(
        'bonos',
        JSON.stringify(history.state.data.data.bonos)
      );
      if (this.esEntidad) {
        this.entidad = history.state.entidad;
        this.nombreEntidad = history.state.nombre;
        this.payload = {
          idPagina: this.idPagina,
          titulo: this.nombreEntidad,
          color: 'naranja-oscuro',
          acumulado: this.entidad.acumulado,
          bloques: this.entidad.bloques
        };
        this.initRevisarIdPagina();
      } else {
        this.initRevisarIdPaginaRamo();
      }
      this.cambiarSufijoPeriodo();
      this.initRevisarOrdenRamos();
    }
  }

  initRevisarIdPagina() {
    this.idPagina = this.entidad;
  }

  public validarSub(ramoId: any, subComponente: any) {
    return ramoId === subComponente ? 'active' : '';
  }

  initRevisarIdPaginaRamo() {
    if (this.ramo) {
      this.idPagina = this.ramo;
    }
  }

  initRevisarOrdenRamos() {
    debugger;
    this.ramos.forEach((ramo: any) => {
      if (ramo.cveRamo && ramo.cveRamo[0] !== null) {
        this.arrRamos.push({
          id: ramo.cveRamo[0],
          nombre: ramo.ramo,
          ramo: ramo
        });
        if (
          ramo.ramo === this.titulo &&
          ramo.cveSegmento === this.segmento &&
          ramo.cveSubSegmento === this.subSegmento
        ) {
          ramo.cveRamosDetalles.forEach((sub: any) => {
            this.arrRamosDeSubsegmento.push({
              id: sub.cveRamo,
              order: sub.orden,
              nombre: sub.descripcion,
              cveSub: ramo.cveSubSegmento
            });
          });
        }
      } else {
        this.arrRamos.push({ id: ramo.id, nombre: ramo.nombre });
      }
    });
  }
  public componenteDatos(componente: any) {
    var valorLogrado;
    var valorFaltante;
    if (componente.faltante && componente.faltante !== 'None') {
      valorFaltante = this.convertirValor(
        componente.tipoDatoFaltante,
        componente.faltante
      );
      valorLogrado = this.convertirValor(
        componente.tipoDato,
        componente.logrado
      );
      return valorLogrado + ' / ' + valorFaltante;
    } else {
      valorLogrado = this.convertirValor(
        componente.tipoDato,
        componente.logrado
      );
      return valorLogrado;
    }
  }

  public detalleBono(componente: any) {
    debugger;
    let loadingCua = this.dialog.open(LoadingComponent);
    this.serviceCua
      .getResumenBono(
        this.subSegmento,
        this.ramo,
        this.cua,
        componente.idCampo,
        this.anio,
        this.periodo,
        this.rol  
      )
      .subscribe(
        (dataDet: any) => {
          if (!dataDet.message) {
            let dataDetalle: any = dataDet.data;
            loadingCua.close();
            let loading = this.dialog.open(LoadingComponent);
            this.serviceCua.getDetalleBono(this.subSegmento,
              this.ramo,
              this.cua,
              componente.detalles[0].id,
              this.anio,
              this.periodo,
              this.rol).subscribe(
              (detBon: any) => {
                if (!detBon.message) {
                  const data = {
                    detalle: componente.id,
                    data: componente,
                    ramo: this.ramo,
                    segmento: this.segmento,
                    anio: this.anio,
                    periodo: this.periodo,
                    componente: componente,
                    componenteId: componente.id,
                    tipoPeriodo: this.tipoPeriodo,
                    resumenId: this.detalle.resumen.resumenId,
                    periodoMaximo: this.periodoMaximo,
                    corte: this.detalle.fechaCorte,
                    generacion: this.detalle.generacion,
                    tipo: this.detalle.tipo,
                    ramos: this.ramos,
                    periodoSufijo: this.periodoSufijo,
                    tipoPeriodoMostrar: this.tipoPeriodoMostrar,
                    periodoMes: this.periodoMes
                  };
                  let dataBono = detBon.data;
                  this.route.navigate(['/ver-detalle'], {
                    state: {
                      data: data,
                      dataDetalle: dataDetalle,
                      dataBono: dataBono,
                    }
                  });
                  loading.close();
                } else {
                  const data = {
                    detalle: componente.id,
                    data: componente,
                    ramo: this.ramo,
                    segmento: this.segmento,
                    anio: this.anio,
                    periodo: this.periodo,
                    componente: componente,
                    componenteId: componente.id,
                    tipoPeriodo: this.tipoPeriodo,
                    resumenId: this.detalle.resumen.resumenId,
                    periodoMaximo: this.periodoMaximo,
                    corte: this.detalle.fechaCorte,
                    generacion: this.detalle.generacion,
                    tipo: this.detalle.tipo,
                    ramos: this.ramos,
                    periodoSufijo: this.periodoSufijo,
                    tipoPeriodoMostrar: this.tipoPeriodoMostrar,
                    periodoMes: this.periodoMes
                  };
                  let dataBono = detBon.data;
                  this.route.navigate(['/ver-detalle'], {
                    state: {
                      data: data,
                      dataDetalle: dataDetalle,
                      dataBono: dataBono,
                    }
                  });
                  loading.close();
                }
              },
              (error) => {
                this.showError(
                  'Error al cargar el servicio. Intenta de nuevo.',
                  'iziToast-icon fas fa-exclamation revealIn'
                );
                loadingCua.close();
              }
            );

            loadingCua.close();
          } else {
            this.showError(
              dataDet.message,
              'iziToast-icon fas fa-exclamation revealIn'
            );
            loadingCua.close();
          }
        },
        (error) => {
          this.showError(
            'Error al cargar el servicio. Intenta de nuevo.',
            'iziToast-icon fas fa-exclamation revealIn'
          );
          loadingCua.close();
        }
      );
  }

  public detalleResumen(componente: any, resumen: any) {
    componente.idCampo = componente.id;
    /*     const data = {
        detalle: componente.id,
        data: componente,
        ramo: $stateParams.ramo,
        segmento: $stateParams.segmento,
        anio: $stateParams.anio,
        periodo: $scope.periodo,
        periodoMaximo:$scope.periodoMaximo,
        resumenId:resumen.resumenId,
        tipoPeriodo: $scope.tipoPeriodo,
        componenteId: componente.id,
        corte: btoa(JSON.stringify($scope.detalle.fechaCorte)),
        ramos: $stateParams.ramos,
        generacion:$scope.detalle.generacion,
        tipo:$scope.detalle.tipo
    };
    $scope.goToPage(ruta.verBonoComponenteDetalle, data); */
  }

  showError(message: string, icon: string) {
    const componentRef = this.toastContainer.createComponent(ToastComponent);
    componentRef.instance.message = message;
    componentRef.instance.show = true;
    componentRef.instance.icon = icon;

    // Opcional: Ocultar el toast automáticamente después de unos segundos
    setTimeout(() => {
      this.toastContainer.clear();
    }, 5000); // El toast se cerrará después de 3 segundos
  }

  public resumenDetalle(componente: any, resumen: any) {
    componente.idCampo = componente.id;
    let loadingCua = this.dialog.open(LoadingComponent);
    this.serviceCua
      .getDetalleResumen(
        this.subSegmento,
        this.ramo,
        this.cua,
        resumen.resumenId,
        componente.id,
        this.anio,
        this.periodo,
        this.rol
      )
      .subscribe(
        (dataDet: any) => {
          if (!dataDet.message) {
            let dataDetalle: any = dataDet.data;
            loadingCua.close();
            let loading = this.dialog.open(LoadingComponent);
            this.serviceCua.getBonosAnio(componente.id).subscribe(
              (dataBon: any) => {
                if (!dataBon.message) {
                  let dataBonoA = dataBon.data;
                  const data = {
                    detalle: componente.id,
                    data: componente,
                    ramo: this.ramo,
                    segmento: this.segmento,
                    anio: this.anio,
                    periodo: this.periodo,
                    componente: componente,
                    componenteId: componente.id,
                    tipoPeriodo: this.tipoPeriodo,
                    resumenId: resumen.resumenId,
                    periodoMaximo: this.periodoMaximo,
                    corte: this.detalle.fechaCorte,
                    generacion: this.detalle.generacion,
                    tipo: this.detalle.tipo,
                    ramos: this.ramos,
                    periodoSufijo: this.periodoSufijo,
                    tipoPeriodoMostrar: this.tipoPeriodoMostrar,
                    periodoMes: this.periodoMes
                  };
                  this.serviceCua.getDetalleBono(this.subSegmento,
                    this.ramo,
                    this.cua,
                    componente.id,
                    this.anio,
                    this.periodo,
                    this.rol).subscribe(
                    (detBon: any) => {
                      if (!detBon.message) {
                        let dataBono = detBon.data;
                        this.route.navigate(['/ver-detalle'], {
                          state: {
                            data: data,
                            dataBonoA: dataBonoA,
                            dataDetalle: dataDetalle,
                            dataBono: dataBono,
                          }
                        });
                        loading.close();
                      } else {
                        loading.close();
                        let dataBono = detBon.data;
                        this.route.navigate(['/ver-detalle'], {
                          state: {
                            data: data,
                            dataBonoA: dataBonoA,
                            dataDetalle: dataDetalle,
                            dataBono: dataBono,
                          }
                        });
                      }
                    },
                    (error) => {
                      this.showError(
                        'Error al cargar el servicio. Intenta de nuevo.',
                        'iziToast-icon fas fa-exclamation revealIn'
                      );
                      loadingCua.close();
                    }
                  );
                } else {
                  loading.close();
                  this.showError(
                    dataBon.message,
                    'iziToast-icon fas fa-exclamation revealIn'
                  );
                }
              },
              (error) => {
                this.showError(
                  'Error al cargar el servicio. Intenta de nuevo.',
                  'iziToast-icon fas fa-exclamation revealIn'
                );
                loadingCua.close();
              }
            );

            loadingCua.close();
          } else {
            this.showError(
              dataDet.message,
              'iziToast-icon fas fa-exclamation revealIn'
            );
            loadingCua.close();
          }
        },
        (error) => {
          this.showError(
            'Error al cargar el servicio. Intenta de nuevo.',
            'iziToast-icon fas fa-exclamation revealIn'
          );
          loadingCua.close();
        }
      );
  }

  goBack() {
    this.route.navigate(['/home']);
  }

  cambiarSufijoPeriodo() {
    if (this.tipoPeriodo === 'Cuatrimestre' || this.tipoPeriodo === 'cuatri') {
      this.tipoPeriodoMostrar = 'Cuatrimestre';
      this.periodoMes = 'Enero-Abril';
      if (this.periodo === 3) {
        this.periodoMes = 'Septiembre-Diciembre';
      }
    } else {
      this.tipoPeriodoMostrar = 'Trimestre';
      this.periodoMes = 'Enero-Marzo';
      if (this.periodo === 3) {
        this.periodoMes = 'Julio-Septiembre';
      }
    }
    if (this.periodo === 2) {
      this.periodoSufijo = 'do';
      if (
        this.tipoPeriodo === 'Cuatrimestre' ||
        this.tipoPeriodo === 'cuatri'
      ) {
        this.periodoMes = 'Mayo-Agosto';
      } else {
        this.tipoPeriodoMostrar = 'Trimestre';
        this.periodoMes = 'Abril-Junio';
      }
    }
    if (this.periodo === 4) {
      this.tipoPeriodoMostrar = 'Trimestre';
      this.periodoSufijo = 'to';
      this.periodoMes = 'Octubre-Diciembre';
    }
  }

  public cambioPeriodo(periodo: any) {
    if (periodo > 0 && periodo <= this.periodoMaximo) {
      let loadingCua = this.dialog.open(LoadingComponent);
      this.serviceCua
        .getDetalleRamPerio(
          this.subSegmento,
          this.ramo,
          this.cua,
          this.anio,
          this.periodo,
          this.rol
        )
        .subscribe(
          (response: any) => {
            if (!response.message) {
              this.detalle = response.data;
              this.periodo = periodo;
              this.cambiarSufijoPeriodo();
            } else {
              this.showError(
                response.message,
                'iziToast-icon fas fa-exclamation revealIn'
              );
              loadingCua.close();
            }
          },
          (error) => {
            this.showError(
              'Error al cargar el servicio. Intenta de nuevo.',
              'iziToast-icon fas fa-exclamation revealIn'
            );
            loadingCua.close();
          }
        );
    }
  }

  verDetallesComponente(componente: any) {
    /*     this.goToPage( ruta.verEntidadAcumuladoComponenteDetalle ,
                    {
                        cua: $scope.cua, idpart: $stateParams.idpart, portal: $stateParams.portal, anio:$stateParams.anio,
                        entidad: $stateParams.entidad,
                        nombre: componente.nombre,
                        ramos: $stateParams.ramos,
                        componenteId: componente.componenteId,
                        componente: componente
                    }
                    ); */
  }

  public convertirCorte(corte: any) {
    var dividirFecha = corte.split('/', 3);
    return (
      'Corte al ' +
      parseInt(dividirFecha[0]) +
      '/' +
      dividirFecha[1] +
      '/' +
      parseInt(dividirFecha[2].slice(-2))
    );
  }

  regresarMenu() {
    this.route.navigate(['/segmentos'], { state: { anio: this.anio } });
  }

  public verDetalles(ramoE: any) {
    debugger;

    let flag = ramoE.ramo ? false : true;
    if (!flag) {
      let loading = this.dialog.open(LoadingComponent);
      this.serviceCua
        .getDetalleSub(
          ramoE.ramo.cveSubSegmento,
          ramoE.id,
          this.cua,
          this.anio,
          ramoE.ramo.periodo,
          this.rol
        )
        .subscribe(
          (dataSeg: any) => {
            if (!dataSeg.message) {
              this.esEntidad = flag;
              this.arrRamos = [];
              this.arrRamosDeSubsegmento = [];

              this.subSegmento = ramoE.ramo.cveSubSegmento;
              this.segmento = ramoE.ramo.cveSegmento;
              this.periodoMaximo = ramoE.ramo.periodo;
              this.tipoPeriodo = ramoE.ramo.tipoPeriodo;
              this.periodo = parseInt(ramoE.ramo.periodo);
              this.ramo = ramoE.id;
              this.titulo = ramoE.nombre;
              this.initRevisarIdPaginaRamo();
              this.initRevisarOrdenRamos();
              this.cambiarSufijoPeriodo();
              this.detalle = dataSeg.data;
              loading.close();
            } else {
              this.showError(
                dataSeg.message,
                'iziToast-icon fas fa-exclamation revealIn'
              );
              loading.close();
            }
          },
          (error) => {
            this.showError(
              'Error al cargar el servicio. Intenta de nuevo.',
              'iziToast-icon fas fa-exclamation revealIn'
            );
            loading.close();
          }
        );
    } else {
      let loadingB = this.dialog.open(LoadingComponent);
      this.serviceCua.getRamoEntidad(ramoE.id, this.cua, this.rol).subscribe(
        (dataSeg: any) => {
          if (!dataSeg.message) {
            this.esEntidad = flag;
            this.arrRamos = [];
            this.arrRamosDeSubsegmento = [];
            this.detalle = dataSeg.data;
            this.entidad = ramoE.id;
            this.initRevisarIdPagina();
            this.payload = {
              idPagina: this.idPagina,
              titulo: this.nombreEntidad,
              color: 'naranja-oscuro',
              acumulado: this.entidad.acumulado,
              bloques: this.entidad.bloques
            };
            this.cambiarSufijoPeriodo();
            this.initRevisarOrdenRamos();
            loadingB.close();
          } else {
            this.showError(
              dataSeg.message,
              'iziToast-icon fas fa-exclamation revealIn'
            );
            loadingB.close();
          }
        },
        (error) => {
          this.showError(
            'Error al cargar el servicio. Intenta de nuevo.',
            'iziToast-icon fas fa-exclamation revealIn'
          );
          loadingB.close();
        }
      );
    }
  }

  public validarTabSub(ramoId: any, idPagina: any) {
    return String(ramoId).substring(0, 2) === String(idPagina).substring(0, 2)
      ? 'activenaranja'
      : '';
  }

  public color(clave: any) {
    if (clave === null || clave === undefined) {
      return 'naranja-oscuro';
    }
    if (clave.includes('PY')) {
      return 'rojo';
    } else if (clave.includes('LC')) {
      return 'rojo-oscuro';
    } else if (clave.includes('VIDA')) {
      return 'verde';
    } else if (clave.includes('DANOS')) {
      return 'morado';
    } else if (clave.includes('AUTOS')) {
      return 'naranja';
    } else if (clave.includes('SALUD')) {
      return 'azul';
    }
    return 'rojo';
  }

  public valoresBloque(valores: any) {
    var valor1;
    var valor2;
    var sufijo1;
    var sufijo2;
    if (valores[0] === undefined || valores[0].sufijo === undefined) {
      sufijo1 = '';
    } else {
      sufijo1 = valores[0].sufijo;
    }
    if (valores[1] === undefined || valores[1].sufijo === undefined) {
      sufijo2 = '';
    } else {
      sufijo2 = valores[1].sufijo;
    }

    if (valores.length > 1) {
      valor1 =
        this.convertirValor(valores[0].tipoDato, valores[0].valorDato) +
        ' ' +
        sufijo1;
      valor2 =
        this.convertirValor(valores[1].tipoDato, valores[1].valorDato) +
        ' ' +
        sufijo2;
      return valor1 + ' / ' + valor2;
    } else {
      valor1 =
        this.convertirValor(valores[0].tipoDato, valores[0].valorDato) +
        ' ' +
        sufijo1;
      return valor1;
    }
  }

  public convertirValor(tipoDato: any, valor: any) {
    if (valor === null) {
      valor = 0;
    }
    if (tipoDato === 'texto') {
      return valor;
    }
    if (tipoDato === 'moneda') {
      var signonegativo = '';
      valor = parseFloat(valor);

      var numformateado = new Intl.NumberFormat('es-MX').format(
        valor.toFixed(2)
      );

      if (Math.sign(Number(valor)) === -1) {
        numformateado = numformateado.replace('-', '');
        signonegativo = '-';
      }
      return (
        signonegativo +
        '$' +
        numformateado.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      );
    }
    if (tipoDato === 'porcentaje') {
      valor = parseFloat(valor);

      return this.truncar(valor * 100, 4) + '%';
    }
    if (tipoDato === 'numero') {
      valor = parseFloat(valor);
      return new Intl.NumberFormat('es-MX').format(valor.toFixed(4));
    }
    return valor;
  }

  truncar(x: any, posiciones = 0) {
    var s = x.toString();
    var decimalLength = s.indexOf('.') + 1;
    var numStr = s.substr(0, decimalLength + posiciones);
    return Number(numStr);
  }

  verDetallesSub(sub: any) {
    /*     $scope.goToPage(ruta.verSubsegmento, {
        'ramo': sub.id,
        'segmento': $stateParams.segmento,
        'anio': $stateParams.anio,
        'tipoPeriodo': $stateParams.tipoPeriodo,
        'subsegmento': sub.cveSub,
        'titulo': $stateParams.titulo,
        'ramos': dataDecodedRamos,
        'periodoMaximo': $stateParams.periodoMaximo,
        'periodo': $stateParams.periodo
    }); */
  }

  calcularPeriodo(ramo: any, anio: any) {
    var periodo = parseInt(ramo.periodo);
    var trimestre = Math.ceil(parseInt(moment().format('M')) / 3);
    var cuatrimestre = Math.ceil(parseInt(moment().format('M')) / 4);
    if (parseInt(anio) === parseInt(moment().format('YYYY'))) {
      if (ramo.tipoPeriodo === 'cuatri' && periodo > cuatrimestre) {
        periodo = cuatrimestre;
      }
      if (ramo.tipoPeriodo === 'tri' && periodo > trimestre) {
        periodo = trimestre;
      }
    }
    return periodo;
  }
}

import { ModalColumnFilterComponent } from '@/app/components/modal-column-filter/modal-column-filter.component';
import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import moment from 'moment-timezone';
import * as XLSX from 'xlsx';
import { DatosCuaService } from '../../service/datosCua.service';

@Component({
  selector: 'app-ver-detalle',
  templateUrl: './ver-detalle.component.html',
})

export class VerDetalleComponent implements OnInit{

  payloadTabla: any = {
    tieneTabla: false,
    detalles: {
      columnas: [],
      filas: []
    }
  };

  selectedColumns: any[] = [];
  searchText: string = '';
  filteredColumns: any[] = [];
  cua: string= '';
  payload:any = [];
  idPagina:any = null;
  
  anio: string = '';
  detalleBono: any = [];
  ramo: any = [];
  componenteDetalle:any  = null;
  periodo: number = 0;
  periodoSufijo = null;
  tipoPeriodo: string = '';
  tipoPeriodoReturn: string = '';
  tipoPeriodoMostrar: string = '';
  periodoMes:any = null;
  cveRamo: string = '';
  cveSegmento: string = '';
  tipo: string = '';
  generacion: string = '';
  anioOriginal: string = '';
  colorTotal: string = '';
  corte : string = '';
  detalles:any = [];


  totalesObj:any = []; 
  logradoObj:any = [];
  faltanteObj:any = [];
  dataDecoded:any = [];
  esBonoComponenteDetalle: boolean = false;
  esBonoDetalle: boolean = false;
  esEntidad: boolean = false;
  dataTabla:any=[];

  constructor(
    private _location: Location,
    public serviceCua: DatosCuaService,
    public dialog: MatDialog,
    public route: Router
  ) { 

    
  }

  ngOnInit() {
    
    this.cua = sessionStorage.getItem('cua')!;
    this.detalleBono = JSON.parse(sessionStorage.getItem('bonos')!);
    if(history.state.dataDetalle.subComponente !== undefined && history.state.dataDetalle.subComponente?.length > 0 ){
      this.esBonoDetalle = true;
      this.detalles = history.state.dataDetalle.subComponente;
    }else if(history.state.dataDetalle.detalles !== undefined && history.state.dataDetalle.detalles?.length > 0 ){
      this.esEntidad = true;
      this.detalles = history.state.dataDetalle.detalles;
      this.armarPantalla();
    }
      this.dataDecoded = history.state.data;
      this.ramo = history.state.data.ramo;
      this.periodo = history.state.data.periodo;
      this.periodoSufijo = history.state.data.periodoSufijo;
      this.tipoPeriodoMostrar = history.state.data.tipoPeriodoMostrar;
      this.periodoMes = history.state.data.periodoMes;
      this.tipoPeriodo = history.state.data.tipoPeriodo;
      this.tipoPeriodoReturn = this.tipoPeriodo;
      this.corte = history.state.data.corte;
      this.cveRamo = history.state.data.ramo;
      this.cveSegmento = history.state.data.segmento;
      this.anio = history.state.data.anio;
      this.tipo = history.state.data.tipo;
      this.generacion = history.state.data.generacion;
      this.anioOriginal = history.state.data.anio;
      this.colorTotal = 'azul';
      this.dataTabla = history.state.dataBono;
      this.totalesObj = history.state.dataBono?.totales;


      this.payload = {
        idPagina: this.idPagina,
        idPadreRamo: this.ramo,
        color: this.color(this.ramo),
        detalles: this.detalles,
        logrado: this.logradoObj,
        faltante: this.faltanteObj,
        totales: this.totalesObj,
        totalesSeparadosPorId: history.state.dataBono?.totales,
        ramo: this.ramo,
        titulo: history.state.dataBonoA? history.state.dataBonoA?.nombre: history.state.dataDetalle?.bono ,
        tieneTabla: false
      };
   
    
      if (this.dataTabla && this.dataTabla.columnas && this.dataTabla.filas) {
        this.payloadTabla.tieneTabla = true;
        

        // Asignación de columnas
        this.payloadTabla.detalles.columnas = this.dataTabla.columnas.map((col: any) => ({
          label: col.label,
          idColumna: col.idColumna,
          selected: false
        }));
        

        // Asignación de filas
        this.payloadTabla.detalles.filas = this.dataTabla.filas.map((fila: any) => {
          let mappedFila = this.payloadTabla.detalles.columnas.map((columna: any) => fila[columna.idColumna]);
          console.log("Fila mapeada:", mappedFila);
          return mappedFila;
        });
      } else {
        console.warn("No se encontraron datos en la respuesta");
        this.payloadTabla.tieneTabla = false;
      }
    
  }

  openColumnFilter(): void {
    const dialogRef = this.dialog.open(ModalColumnFilterComponent, {
      width: '600px',  // Especifica el ancho del modal
      height: 'auto',  // La altura será automática según el contenido
      maxHeight: '90vh',
      data: { columns: this.payloadTabla.detalles.columnas }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.selectedColumns = result.filter((col: { selected: any; }) => col.selected);
        this.filteredColumns = this.selectedColumns.map(col => col.label);
      }
    });
  }

  public nuevaDataTabla(lafila:any, anio:any){
/* 
    var filaobj = $('#'+lafila);

    $('.barraTotal.'+$scope.colorTotal).removeClass($scope.colorTotal);
    filaobj.addClass($scope.colorTotal);

    if(anio.trim()!=='') {
        detalleTabla(anio, 2);
    }else{
        $scope.lanzarMensaje(2, 'Este Total no tiene año de búsqueda');
        $scope.payload.tieneTabla = false;
    } */
  }

  public detalleResumen(componente:any) {
/*     const data = {
        detalle: componente.detalleId,
        data:componente,
        ramo: $stateParams.ramo,
        segmento:$stateParams.segmento,
        anio: $stateParams.anio,
        periodo:$scope.periodo,
        componente : $scope.detalleComponente.id,
        dataComponente: btoa(JSON.stringify(dataDecoded)),
        corte:$stateParams.corte,
        tipo:$scope.tipo,
        generacion:$scope.generacion,
        resumenId: $stateParams.resumenId,
        componenteId: $stateParams.componenteId,
        periodoMaximo:$scope.periodoMaximo,
        ramos: $stateParams.ramos,


    }
    $scope.goToPage(ruta.verBonoComponenteDetalle,data); */
}

  armarPantalla() {
    this.logradoObj =
    {
        titulo: history.state.dataDetalle.bono,
        icono: history.state.dataDetalle.icono,
        componentes: history.state.dataDetalle.detalles,
        bonoLogrado: {
            titulo: history.state.dataBono?.totales[0]?.nombre,
            eldato: this.convertirValor(history.state.dataBono?.totales[0]?.tipo, history.state.dataBono?.totales[0]?.valor)
        },
    };
    if (history.state.dataBono?.totales[1]) {
      this.logradoObj.bonoPosible = { titulo: history.state.dataBono?.totales[1]?.nombre,
                                            eldato: this.convertirValor(history.state.dataBono?.totales[1]?.tipo, history.state.dataBono?.totales[1]?.valor)
        };
    }
    var faltante:any = [];
    this.detalles.forEach((detalle:any) => {
       if (detalle.faltante !== null && detalle.faltante !== 'None') {
          faltante.push(detalle);
      }
      
    });
    this.faltanteObj =
    {
        titulo: 'Para lograr el bono proyectado te falta:',
        componentes: faltante
    };

    //detalleTabla($stateParams.anio, 1);

}


  goBack(){
    this.route.navigate(['/home']);
  }

  public validarTabSub(ramoId:any, idPagina:any){
    return (ramoId.substring(0,2) === idPagina.substring(0,2)) ? 'activenaranja' : '';
  }


  public color(clave:any) {

    if (clave === null || clave === undefined){
        return 'naranja-oscuro';
    }
    if (clave.includes('PY')) {
        return 'rojo';
    }
    else if (clave.includes('LC')) {
        return 'rojo-oscuro';
    }
    else if (clave.includes('VIDA')) {
        return 'verde';
    }
    else if (clave.includes('DANOS')) {
        return 'morado';
    }
    else if (clave.includes('AUTOS')) {
        return 'naranja';
    }
    else if (clave.includes('SALUD')) {
        return 'azul';
    }
    return 'rojo';
  }

  public valoresBloque(valores:any) {
    var valor1;
    var valor2;
    var sufijo1;
    var sufijo2;
    if(valores[0] === undefined  || valores[0].sufijo === undefined){
        sufijo1='';
    } else {
        sufijo1 = valores[0].sufijo;
    }
    if(valores[1] === undefined || valores[1].sufijo === undefined){

        sufijo2='';
    } else {
        sufijo2= valores[1].sufijo;
    }

    if(valores.length > 1 ){
        valor1 = this.convertirValor(valores[0].tipoDato,valores[0].valorDato) +' '+ sufijo1;
        valor2= this.convertirValor(valores[1].tipoDato,valores[1].valorDato) +' ' + sufijo2;
        return valor1 + ' / ' + valor2;
    } else {
        valor1= this.convertirValor(valores[0].tipoDato,valores[0].valorDato) +' '+ sufijo1;
        return valor1;
    }
  }

  public convertirValor(tipoDato:any, valor:any) {
    if(valor === null){
        valor = 0;
    }
    if (tipoDato === 'texto'){
        return valor;
    }
    if (tipoDato === 'moneda') {

        var signonegativo = '';
        valor = parseFloat(valor);

        var numformateado = new Intl.NumberFormat('es-MX').format(valor.toFixed(2));

        if(Math.sign(Number(valor)) === -1)
        {
            numformateado = numformateado.replace('-','');
            signonegativo='-';
        }
        return signonegativo + '$' + numformateado.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    if (tipoDato === 'porcentaje') {
        valor = parseFloat(valor);

        return this.truncar((valor * 100),4) + '%';
    }
    if (tipoDato === 'numero') {
        valor = parseFloat(valor);
        return new Intl.NumberFormat('es-MX').format(valor.toFixed(4));
    }
    return valor;
  }

  truncar(x:any, posiciones = 0) {
    var s = x.toString();
    var decimalLength = s.indexOf('.') + 1;
    var numStr = s.substr(0, decimalLength + posiciones);
    return Number(numStr);
  }

  componenteDatos(componente:any) {
    
    var valorLogrado;
    var valorFaltante;
    if(componente.faltante && componente.faltante !== 'None'){
        valorFaltante = this.convertirValor(componente.tipoDatoFaltante,componente.faltante);
        valorLogrado= this.convertirValor(componente.tipoDato,componente.logrado);
        return valorLogrado + ' / ' + valorFaltante;
    } else {
        valorLogrado= this.convertirValor(componente.tipoDato,componente.logrado);
        return valorLogrado;
    }
};

  public componenteBono(componente:any,resumen?:any) {
    
    var valorLogrado;
    var campo2;
    if(resumen === 'resumen'){
        return this.componenteDatos(componente);
    }
    if(componente.logrado && componente.campo2 !== ''){
        campo2 = this.convertirValor(componente.tipoDatoCampo2,componente.campo2);
        valorLogrado= this.convertirValor(componente.tipoDato,componente.logrado);
        return valorLogrado + ' / ' + campo2;
    } else {
        valorLogrado= this.convertirValor(componente.tipoDato,componente.logrado);
        return valorLogrado;
    }
};

  verDetallesComponente(componente:any) {

/*     this.goToPage( ruta.verEntidadAcumuladoComponenteDetalle ,
                    {
                        cua: $scope.cua, idpart: $stateParams.idpart, portal: $stateParams.portal, anio:$stateParams.anio,
                        entidad: $stateParams.entidad,
                        nombre: componente.nombre,
                        ramos: $stateParams.ramos,
                        componenteId: componente.componenteId,
                        componente: componente
                    }
                    ); */

  }

  regresarMenu(){
    this._location.back();
  }

  public verDetalles(ramoE:any) {
    if(ramoE.ramo){
        const ramo= ramoE.ramo;
        var periodo =this.calcularPeriodo(ramo, history.state.anio);
/*         if(ramo.cveSubSegmento === ramo.cveSegmento){
            $scope.goToPage(ruta.verRamo,{
                'ramo':ramo.cveRamo[0],
                'segmento' :ramo.cveSegmento,
                'anio':$stateParams.anio,
                'tipoPeriodo':ramo.tipoPeriodo,
                'titulo':ramo.ramo,
                'ramos': dataDecoded,
                'periodoMaximo':ramo.periodo,
                'periodo':periodo
            });
        }
        else {
            $scope.goToPage(ruta.verSubsegmento,{
                'ramo':ramo.cveRamo[0],
                'segmento' :ramo.cveSegmento,
                'anio':$stateParams.anio,
                'tipoPeriodo':ramo.tipoPeriodo,
                'subsegmento':ramo.cveSubSegmento,
                'titulo':ramo.ramo,
                'ramos':dataDecoded,
                'periodoMaximo':ramo.periodo,
                'periodo':periodo
            });
        } */
    }
    else {
/*         $scope.goToPage(ruta.verEntidad,{
            'entidad':ramoE.id,
            'nombre':ramoE.nombre,
            'anio':$stateParams.anio,
            'ramos': dataDecoded
        }); */
    }
  }

  exportar() {
    
    if (this.dataTabla.filas.length > 0) {
      

      // Crea un Blob a partir del contenido y lo convierte a una URL
      const blob = this.buildExcelContent(this.dataTabla.filas);
      const url = URL.createObjectURL(blob);

      // Crea un enlace y simula un clic para descargar el archivo
      const link = document.createElement('a');
      link.href = url;
      link.download = `extraccion_:${this.payload.titulo}_2024-09-03T13_53_49.xlsx`;
      link.click();

      // Libera la URL del Blob después de la descarga
      URL.revokeObjectURL(url);
    } else {
      console.error('No hay datos para generar el archivo Excel.');
    }
  }

  buildExcelContent(data: any[]) {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    return new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  }

  calcularPeriodo (ramo:any,anio:any) {
    var periodo = parseInt(ramo.periodo);
    var trimestre = Math.ceil(parseInt(moment().format("M")) / 3);
    var cuatrimestre = Math.ceil(parseInt(moment().format("M")) / 4);
    if (parseInt(anio) === parseInt(moment().format("YYYY"))) {
        if(ramo.tipoPeriodo === "cuatri" && periodo > cuatrimestre){
            periodo =  cuatrimestre
        }
        if(ramo.tipoPeriodo === "tri" && periodo > trimestre){
            periodo =  trimestre;
        }
    }
    return periodo;
  }
  public cambioPeriodo(periodo:any) {

    /*     if ((periodo > 0) && (periodo <=  this.periodoMaximo)) {
            $rootScope.loading = true;
            bcaWsFactory.getDetallesRamoP($stateParams.segmento, $stateParams.ramo, $stateParams.idpart,
                $stateParams.cua, periodo, $stateParams.anio)
                .then(function (response) {
                    if (!response.message) {
                        $scope.detalle = response.data;
                        $scope.periodo = periodo;
                        $rootScope.loading = false;
                        $scope.cambiarSufijoPeriodo($scope);
                    } else {
                        $scope.lanzarMensaje(2, "No se encontraron bonos en el periodo: " + periodo)
                        $rootScope.loading = false;
                    }
                });
        } */
  }
  
  convertirCorte(corte:any) {
    var dividirFecha = corte.split('/', 3);
    return 'Corte al ' + parseInt(dividirFecha[0]) + '/' + dividirFecha[1] + '/' + parseInt(dividirFecha[2].slice(-2));
  } 

  public regresarBono () {
    this._location.back();

  }
}
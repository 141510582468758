import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], searchText: string, filteredColumns: string[]): any[] {
    if (!items || !filteredColumns || filteredColumns.length === 0) {
      return items; // Si no hay columnas seleccionadas, devuelve todos los items
    }

    if (!searchText) {
      return items; // Si no hay texto de búsqueda, devuelve todos los items
    }

    // Convertir el texto de búsqueda a minúsculas
    const lowerSearchText = searchText.toLowerCase();

    // Filtrar las filas basadas en el texto de búsqueda y las columnas seleccionadas
    return items.filter(item => 
      filteredColumns.some((columnLabel, columnIndex) => {
        const itemValue = item[columnIndex]; // Extraer el valor de la columna en base a su índice
        return itemValue && itemValue.toString().toLowerCase().includes(lowerSearchText);
      })
    );
  }
}
<div #toastContainer></div>
<div class="separacion-interseccion-1"></div>
<div class="row w100 justify-content-center">
  <div class="col-md-4 col-sm-5">
    <div class="separacion-interseccion-3"></div>
    <div class="login-titulo1">Acceso a bonos</div>
    <div class="login-titulo2">Ingresa CUA</div>

    <form style="margin-top: 25px" [formGroup]="formCua">
      <div style="display: flex; flex-direction: column; width: 85%">
        <mat-form-field appearance="fill" style="width: 100%">
          <input matInput type="text" placeholder="Ingresa CUA" onlyNumber formControlName="cua" />
          <mat-icon style="color: #cdcdcd" class="material-symbols-outlined" matSuffix>lock</mat-icon>
        </mat-form-field>

        <div style="display: flex; justify-content: flex-end; margin-top: 10px">
          <button [disabled]="formCua.invalid" mat-stroked-button class="m-2 btn-naranja"
            (click)="getCua()">Buscar</button>
        </div>
      </div>
    </form>
  </div>
</div>
import { Component, Inject, } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface DialogData {
  message: string;
  seccion: boolean;
}
@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
})
export class LoadingComponent{
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dialogRef: MatDialogRef<LoadingComponent>,
  ) {
    dialogRef.disableClose = true;
   }
}

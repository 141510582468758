<div #toastContainer></div>
<div class="container-fluid bg-gris">
  <div class="separacion-interseccion-3"></div>

  <!--                 ||||||||||tarjeta colores y titulo|||||||||||||-->
  <div class="contenedor-tarjeta-info-1">
    <div class="row d-flex flex-wrap align-items-end">
      <div class="row">
        <div class="col-md-1">
          <button class="btn-texto waves-effect waves-orange btn-flat" (click)="regresarMenu()">
            <mat-icon class="color-icon">arrow_back</mat-icon>
          </button>
        </div>
        <div class="col-md-11 heder-bloques" *ngIf="arrRamosDeSubsegmento.length == 0">
          <div class="otroramotxt {{
              ramo.id == idPagina ? 'activenaranja' : ''
            }} " *ngFor="let ramo of arrRamos" (click)="verDetalles(ramo)">
            {{ ramo.nombre }}
          </div>
        </div>
        <div class="col-md-11 heder-bloques" *ngIf="arrRamosDeSubsegmento.length > 0">
          <div class="otroramotxt {{ validarTabSub(ramo.id, idPagina) }} " *ngFor="let ramo of arrRamos"
            (click)="verDetalles(ramo)">
            {{ ramo.nombre }}
          </div>
        </div>
      </div>
      <div class="separacion-interseccion-3"></div>
      <div class="row">
        <div class="col-md-12">
          <div class="row 'bg-gris'">
            <div class="col-md-3">
              <div class="icono-y-texto">
                <mat-icon>account_box</mat-icon>
                <span class="eltexto">CUA</span>
                <span class="negritas">{{ cua }}</span>
              </div>
            </div>
            <div class="col-md-6">
              <!--                 ||||||||||ramos para cuando estamos en subsegmento|||||||||||||-->
              <div class="row d-flex flex-wrap justify-content-center" *ngIf="!esEntidad">
                <div class="titulo-Segmento text-center unramotxtsub {{
                    validarSub(ramohijo.id, subSegmento)
                  }}" *ngFor="let ramohijo of arrRamosDeSubsegmento" (click)="verDetallesSub(ramohijo)">
                  {{ ramohijo.nombre }}
                </div>
              </div>
              <div class="titulo-Segmento text-center" *ngIf="esEntidad">
                {{ detalle.acumulado.nombreAcumulado }}
              </div>
              <div class="titulo-Segmento text-center" *ngIf="bandera">
                No se encontro información para esta Entidad
              </div>
            </div>
            <div class="col-md-3 space-cua">
              <span class="unlink" *ngIf="_ban_portalEntrada=='intermediarios' ?  false : true" (click)="goBack()">
                Cambiar CUA
              </span>

              <!--               <i class="fas fa-info-circle unicono gris iconolink" ng-click="irAGlosario()"
                ng-show="ban_existe_o_no"></i>
              <i class="fas fa-info-circle unicono gris iconolink" ng-click="irAGlosarioEntidad()"
                ng-show="ban_existe_o_no_glosario_entidad"></i> -->
            </div>
          </div>
        </div>
      </div>

      <!--                 ||||||||||elemento fake|||||||||||||-->
      <div class="elementofake" *ngIf="detalle.acumulado.detalle.length > 0">
        &nbsp;
      </div>
      <!--                 ||||||||||fin elemento fake|||||||||||||-->
    </div>
  </div>
  <br />
  <div *ngIf="esEntidad">
    <div class="contenedor-tarjeta-info-2" *ngIf="detalle.acumulado.detalle.length > 0">
      <div class="row justify-content-center">
        <div class="col-md-10 sin-padding">
          <div class="row tarjeta-borde-color-up {{
              color(detalle.acumulado.detalle.ramo)
            }}">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-6 col-sm-6 padding-10-0">
                  <div class="titulo-Negritas">
                    {{ detalle.acumulado.nombreAcumulado }}
                  </div>
                </div>
              </div>
              <div *ngFor="let componente of detalle.acumulado.detalle">
                <div class="row">
                  <div class="col-md-6 col-sm-6 padding-10-0">
                    <div>{{ componente.nombre }}</div>
                  </div>
                  <div class="col-md-6 col-sm-6 padding-10-0">
                    <div class="titulo-Negritas negritas azul seccion2-heder">
                      {{ valoresBloque(componente.valores) }}
                    </div>
                    <div class="detalles text-center texto-subtitulo">
                      {{ componente.subtitulo }}
                    </div>
                    <div class="detalles-componente text-right" *ngIf="componente.tieneDetalle"
                      (click)="verDetallesComponente(componente)">
                      Ver detalles
                    </div>
                  </div>
                </div>
                <div class="linea-horizontal-simple"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--                 |||||||||| para la data de ENTIDAD (bloques) |||||||||||||-->
    <div class="row d-flex flex-wrap align-items-end justify-content-centerx justify-content-between">
      <div class="contenedor-tarjeta-bloque flex-fill align-self-start" *ngFor="let b of detalle.bloques">
        <div class="tarjeta-bloque">
          <div class="titulo-Bloque {{ color(b.color) }} d-flex align-items-center">
            <div class="barrita-color"></div>
            {{ b.nombre }}
          </div>
          <div class="row" *ngFor="let fila of b.componentes; let id = index">
            <div class="col-md-6 col-sm-6 titulo-Negritas fila-periodo d-flex align-items-center {{
                id % 2 === 0 ? 'bg-gris' : ''
              }}">
              <div>{{ fila.nombre }}</div>
            </div>
            <div class="col-md-6 col-sm-6 titulo-Negritas fila-periodo d-flex align-items-center justify-content-end {{
                id % 2 === 0 ? 'bg-gris' : ''
              }}">
              <div class="space-cua">
                <div>
                  {{
                  convertirValor(
                  fila.valores[0].tipoDato,
                  fila.valores[0].valorDato
                  )
                  }}
                  {{ fila.valores[0].sufijo }}
                </div>
                <div class="negritas azul">
                  {{
                  convertirValor(
                  fila.valores[1].tipoDato,
                  fila.valores[1].valorDato
                  )
                  }}
                  {{ fila.valores[1].sufijo }}
                </div>
                <div class="detalles-componente" *ngIf="fila.tieneDetalle" (click)="verDetallesComponente(fila)">
                  Ver detalles
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!esEntidad">
    <!--                 ||||||||||tarjeta data Acumulado |||||||||||||-->
    <div class="contenedor-tarjeta-info-2" *ngIf="detalle.acumulado.detalle.length > 0">
      <div class="row justify-content-center">
        <div class="col-md-10 sin-padding">
          <div class="row tarjeta-borde-color-up {{
              color(detalle.acumulado.detalle.ramo)
            }}">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-6 col-sm-6 padding-10-0">
                  <div class="titulo-Negritas">
                    {{ detalle.acumulado.nombreAcumulado }}
                  </div>
                </div>
              </div>
              <div *ngFor="let componente of detalle.acumulado.detalle">
                <div class="row">
                  <div class="col-md-6 col-sm-6 padding-10-0">
                    <div>{{ componente.nombre }}</div>
                  </div>
                  <div class="col-md-6 col-sm-6 padding-10-0">
                    <div class="titulo-Negritas negritas azul seccion2-heder">
                      {{ valoresBloque(componente.valores) }}
                    </div>
                    <div class="detalles text-center texto-subtitulo">
                      {{ componente.subtitulo }}
                    </div>
                    <div class="detalles-componente" *ngIf="componente.tieneDetalle"
                      (click)="verDetallesComponente(componente)">
                      Ver detalles
                    </div>
                  </div>
                </div>
                <div class="linea-horizontal-simple"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--                 |||||||||| para la data de RAMO (bonos) |||||||||||||-->
    <div>
      <!--                 ||||||||||tiempos trimestres meses |||||||||||||-->
      <div class="row text-center justify-content-center">
        <div class="col-md-7 Xoffset-4">
          <div class="row align-items-center">
            <div class="col-md-3 col-sm-3 sin-padding">
              <button class="btn-icono btn-flat" id="btn-anio-izquierda">
                <mat-icon class="chevron_color" (click)="cambioPeriodo(periodo - 1)">chevron_left</mat-icon>
              </button>
            </div>
            <div class="col-md-6 col-sm-6 sin-padding">
              <div>
                {{ periodo }}{{ periodoSufijo }} {{ tipoPeriodoMostrar }} &nbsp;
                <span class="titulo-Negritas">{{ periodoMes }}</span>
              </div>
            </div>
            <div class="col-md-3 col-sm-3 sin-padding">
              <button class="btn-icono btn-flat" id="btn-anio-derecha">
                <mat-icon class="chevron_color" (click)="cambioPeriodo(periodo + 1)">chevron_right</mat-icon>
              </button>
            </div>
          </div>

          <div class="text-center" *ngIf="detalle.fechaCorte">
            {{ convertirCorte(detalle.fechaCorte) }}
          </div>

          <div class="text-center row" *ngIf="detalle.generacion">
            <div class="col-md-6">
              Generación:
              <span class="titulo-Negritas">{{ detalle.generacion }}</span>
            </div>
            <div *ngIf="detalle.tipo != 0" class="col-md-6 text-left">
              Tipo:
              <span class="titulo-Negritas" *ngIf="detalle.tipo != 0">{{
                detalle.tipo
                }}</span>
            </div>
          </div>

          <div class="text-center" *ngIf="!detalle">
            <span class="titulo-Negritas">No se encontraron bonos</span>
          </div>
        </div>
      </div>
      <!--                 ||||||||||fin tiempos trimestres meses |||||||||||||-->

      <div class="separacion-interseccion-3"></div>

      <!--                 ||||||||||contenedor RESUMEN|||||||||||||-->
      <div class="row">
        <div class="col-md-10 offset-md-1">
          <!--                 ||||||||||tarjeta datos |||||||||||||-->
          <div class="tarjeta-blanca-datos" *ngIf="detalle.resumen.componentes.length > 0">
            <div class="row" *ngIf="detalle.resumen.nombreComponente">
              <div class="col-md-6 col-sm-6 padding-10-0">
                <div class="titulo-Negritas">
                  {{ detalle.resumen.nombreComponente }}
                </div>
              </div>
            </div>

            <div *ngFor="let componente of detalle.resumen.componentes">
              <div class="row">
                <div class="col-md-6 col-sm-6 padding-10-0">
                  <div>{{ componente.nombre }}</div>
                </div>
                <div class="col-md-6 col-sm-6 padding-10-0 seccion2-heder">
                  <div class="titulo-Negritas negritas azul">
                    {{ componenteDatos(componente) }}
                  </div>
                  <!--                   <div class="detalles" *ngIf="
                      componente.tieneDetalle &&
                      componente.subComponente.length == 0
                    " (click)="detalleResumen(componente, detalle.resumen)">
                    Ver detalles

                    *ngIf="
                      componente.tieneDetalle &&
                      componente.subComponente.length > 0
                    " 
                  </div> -->
                  <div class="detalles" (click)="resumenDetalle(componente, detalle.resumen)">
                    Ver detalles
                  </div>
                </div>
              </div>
              <div class="linea-horizontal-simple"></div>
            </div>
          </div>
          <!--                 ||||||||||fin tarjeta datos |||||||||||||-->

          <div class="separacion-interseccion-2"></div>

          <!--                 ||||||||||BONOS |||||||||||||-->

          <!--                 ||||||||||tarjeta datos |||||||||||||-->
          <div class="tarjeta-blanca-datos" *ngFor="let bono of detalle.bonos">
            <div class="row">
              <div class="col-md-6 col-sm-6 padding-10-0">
                <div *ngIf="bono.icono != 'None'" class="contenedor-icono">
                  <img src="{{ bono.icono }} " class="img-icono" />
                </div>
                <span class="titulo-Negritas">{{ bono.bono }}</span>
              </div>
              <div class="col-md-6 col-sm-6 padding-10-0 seccion2-heder">
                <div *ngIf="bono.detalles != []">
                  <button class="btn-icono btn-flat" id="btn-bono" *ngIf="bono.detalles.length > 0">
                    <mat-icon class="chevron_color_bono" (click)="detalleBono(bono)">chevron_right</mat-icon>
                  </button>
                </div>
              </div>
            </div>

            <div class="row" *ngIf="bono.totales[0]">
              <div class="col-md-6 col-sm-6 padding-10-0">
                <div class="contenedor-icono"></div>
                <span class="">{{ bono.totales[0].etiqueta }}</span>
              </div>
              <div class="col-md-6 col-sm-6 padding-10-0 seccion2-heder">
                <div class="titulo-Negritas negritas azul">
                  {{
                  convertirValor(
                  bono.totales[0].tipoDato,
                  bono.totales[0].total
                  )
                  }}
                </div>
              </div>
            </div>

            <div class="row" *ngIf="bono.totales[1]">
              <div class="col-md-6 col-sm-6 padding-10-0">
                <div class="contenedor-icono"></div>
                <span class="">{{ bono.totales[1].etiqueta }}</span>
              </div>
              <div class="col-md-6 col-sm-6 padding-10-0 seccion2-heder">
                <div class="titulo-Negritas negritas azul">
                  {{
                  convertirValor(
                  bono.totales[1].tipoDato,
                  bono.totales[1].total
                  )
                  }}
                </div>
              </div>
            </div>
          </div>
          <!--                 ||||||||||fin tarjeta datos |||||||||||||-->
        </div>
      </div>
      <!--                 ||||||||||fin contenedor central |||||||||||||-->
    </div>
  </div>
  <div class="separacion-interseccion-2"></div>
</div>